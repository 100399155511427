<template>
    <h1
        class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold text-gray-400"
    >
        <span class="mt-2 flex w-full items-center"> DOCUMENTOS</span>
    </h1>
    <empty-state
        title="Nenhum documento pessoal encontrado"
        message="Este usuário ainda não possui documentos registrados."
        v-if="!loading && documents && documents.length === 0"
    />
    <div class="flex flex-col gap-4">
        <div
            class="flex items-center justify-between rounded-md p-4 shadow dark:bg-gray-800"
            v-for="document in documents"
            v-bind:key="document.id"
        >
            <div class="flex items-center gap-4">
                <div
                    class="flex h-8 w-8 items-center justify-center rounded-md dark:text-white"
                >
                    <DocumentIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                </div>
                <p class="font-medium dark:text-white">
                    {{ formatDocumentName(document.collection_name) }}
                </p>
            </div>
            <div class="flex items-center gap-2">
                <div
                    v-if="document.status == 'approved'"
                    class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                >
                    <div class="mr-10 flex items-center justify-center">
                        <div
                            class="flex h-10 w-10 items-center justify-center rounded-full bg-green-500"
                        >
                            <CheckIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                            />
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-bold text-gray-800">
                                Validado
                            </h3>
                            <p class="text-gray-600">
                                Por: {{ document.reviewed_by.name }}
                            </p>
                            <p class="text-gray-600">
                                Em: {{ formatDate(document.reviewed_at) }}
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    v-if="document.status == 'pending'"
                    class="mr-10 flex items-center rounded-full border border-yellow-300 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-700"
                >
                    <ExclamationCircleIcon
                        class="mr-2 h-5 w-5 flex-shrink-0 text-yellow-700"
                    />
                    <span>Pendente Aprovação</span>
                </div>

                <div
                    v-if="document.status == 'reproved'"
                    class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                >
                    <div class="mr-10 flex items-center justify-center">
                        <div
                            class="flex h-10 w-10 items-center justify-center rounded-full bg-red-500"
                        >
                            <ExclamationTriangleIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                            />
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-bold text-gray-800">
                                Reprovado
                            </h3>
                            <p class="text-gray-600">
                                Por: {{ document.reviewed_by.name }}
                            </p>
                            <p class="text-gray-600">
                                Em: {{ formatDate(document.reviewed_at) }}
                            </p>
                        </div>
                    </div>
                </div>

                <button
                    @click="openLink(document.temporary_url)"
                    class="flex items-center rounded-md bg-blue-500 px-4 py-2 font-medium text-white"
                >
                    <PhotoIcon class="mr-2 h-5 w-5 text-gray-400" />
                    Visualizar
                </button>

                <button
                    @click="openModal(document)"
                    class="flex items-center rounded-md bg-yellow-500 px-4 py-2 font-medium text-white"
                >
                    <PencilSquareIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                    />
                    Editar
                </button>
                <button
                    v-if="
                        document.status != 'approved' &&
                        document.status !== null
                    "
                    @click="updateStatus(document.id, 'approved')"
                    class="flex items-center rounded-md bg-green-500 px-4 py-2 font-medium text-white"
                >
                    <CheckBadgeIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                    />
                    Validar
                </button>
                <button
                    v-if="
                        document.status !== 'reproved' &&
                        document.status !== 'approved' &&
                        document.status !== null
                    "
                    @click="updateStatus(document.id, 'reproved')"
                    class="flex items-center rounded-md bg-red-500 px-4 py-2 font-medium text-white"
                >
                    <ExclamationTriangleIcon
                        class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                    />
                    Reprovar
                </button>
            </div>
        </div>
    </div>

    <h1
        class="mb-3 flex flex-row flex-wrap content-center text-sm font-bold text-gray-400"
    >
        <span class="mt-2 flex w-full items-center">
            DOCUMENTOS CONTA BANCÁRIA
        </span>
    </h1>
    <empty-state
        title="Nenhum documento bancário encontrado"
        message="Este usuário ainda não cadastrou nenhuma conta bancária."
        v-if="
            !loading &&
            documentsBankAccount &&
            documentsBankAccount.length === 0
        "
    />
    <div class="flex flex-col gap-4">
        <div
            class="flex items-center justify-between rounded-md p-4 shadow dark:bg-gray-800"
            v-for="document in documentsBankAccount"
            v-bind:key="document.id"
        >
            <div class="flex items-center gap-4">
                <div
                    class="flex h-8 w-8 items-center justify-center rounded-md dark:text-white"
                >
                    <DocumentIcon class="h-5 w-5 flex-shrink-0 text-gray-400" />
                </div>
                <p class="font-medium dark:text-white">
                    {{ formatDocumentName(document.collection_name) }}
                </p>
            </div>
            <div class="flex items-center gap-2">
                <div
                    v-if="document.status == 'approved'"
                    class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                >
                    <div class="mr-10 flex items-center justify-center">
                        <div
                            class="flex h-10 w-10 items-center justify-center rounded-full bg-green-500"
                        >
                            <CheckIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                            />
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-bold text-gray-800">
                                Validado
                            </h3>
                            <p class="text-gray-600">
                                Por: {{ document.reviewed_by.name }}
                            </p>
                            <p class="text-gray-600">
                                Em: {{ formatDate(document.reviewed_at) }}
                            </p>
                        </div>
                    </div>
                </div>

                <div
                    v-if="document.status == 'pending'"
                    class="mr-10 flex items-center rounded-full border border-yellow-300 bg-yellow-100 px-4 py-2 text-sm font-medium text-yellow-700"
                >
                    <ExclamationCircleIcon
                        class="mr-2 h-5 w-5 flex-shrink-0 text-yellow-700"
                    />
                    <span>Pendente Aprovação</span>
                </div>

                <div
                    v-if="document.status == 'reproved'"
                    class="mr-4 flex items-center justify-center rounded-lg bg-white p-4 shadow-md"
                >
                    <div class="mr-10 flex items-center justify-center">
                        <div
                            class="flex h-10 w-10 items-center justify-center rounded-full bg-red-500"
                        >
                            <ExclamationTriangleIcon
                                class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                            />
                        </div>
                        <div class="ml-3">
                            <h3 class="text-lg font-bold text-gray-800">
                                Reprovado
                            </h3>
                            <p class="text-gray-600">
                                Por: {{ document.reviewed_by.name }}
                            </p>
                            <p class="text-gray-600">
                                Em: {{ formatDate(document.reviewed_at) }}
                            </p>
                        </div>
                    </div>
                </div>

                <button
                    @click="openLink(document.temporary_url)"
                    class="flex items-center rounded-md bg-blue-500 px-4 py-2 font-medium text-white"
                >
                    <PhotoIcon class="mr-2 h-5 w-5 text-gray-400" />
                    Visualizar
                </button>
            </div>
        </div>
    </div>

    <modal class="4xl" :open="show" @close="show = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Alterar Documento -
            {{ formatDocumentName(this.document.collection_name) }}
        </h1>

        <input
            class="block w-full cursor-pointer rounded-lg border border-gray-300 bg-gray-50 text-sm text-gray-900 focus:outline-none dark:border-gray-600 dark:bg-gray-700 dark:text-gray-400 dark:placeholder-gray-400"
            id="file_input"
            type="file"
            ref="file"
        />

        <div class="mt-3 flex justify-center">
            <button
                :disabled="loading"
                type="button"
                @click.prevent="sendDocument"
                :loading="loading"
                class="mb-3 inline-flex w-full max-w-xs justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
            >
                <PencilSquareIcon
                    class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                />
                Editar
            </button>
        </div>
    </modal>

    <modal class="4xl" :open="decisionModal" @close="decisionModal = false">
        <div
            class="flex min-h-fit w-full flex-col flex-wrap space-y-3 divide-y divide-slate-500 text-white"
        ></div>
        <h1
            class="mb-2 flex flex-row flex-wrap content-center items-center border-b border-slate-500 pb-1 text-lg text-sm font-bold text-white"
        >
            Validação
        </h1>

        <p class="text-white">
            Você tem certeza que deseja
            {{ decision == "approved" ? "validar" : "reprovar" }} este
            documento?
        </p>

        <div class="mt-3 flex justify-center space-x-3">
            <button
                :disabled="loading"
                type="button"
                @click.prevent="updateStatusFetch(decisionDocumment, decision)"
                :loading="loading"
                class="mb-3 inline-flex w-full max-w-xs justify-center rounded-md border border-transparent bg-green-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
            >
                <CheckIcon
                    class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                />
                Sim
            </button>
            <button
                type="button"
                @click.prevent="decisionModal = false"
                class="mb-3 inline-flex w-full max-w-xs justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
            >
                <ExclamationTriangleIcon
                    class="h-5 w-5 flex-shrink-0 text-gray-400 text-white"
                />
                Não
            </button>
        </div>
    </modal>
    <modal
        class="min-h-full"
        size="4xl"
        :open="showDocumentModal"
        @close="showDocumentModal = false"
    >
        <div
            class="flex min-h-full w-full flex-col space-y-3 divide-y divide-slate-500 text-white"
            style="height: 1000px;"
        >
            <h1
                class="mb-2 border-b border-slate-500 pb-1 text-lg font-bold text-white"
            >
                Documento
            </h1>

            <div class="flex flex-grow items-stretch h-full">
                <iframe
                    :src="urlDocument"
                    class="h-full w-full"
                    frameborder="0"
                    allowfullscreen
                ></iframe>
            </div>

            <div class="mt-5 flex justify-center space-x-3">
                <button
                    type="button"
                    @click.prevent="showDocumentModal = false"
                    class="mb-3 inline-flex w-full max-w-xs justify-center rounded-md border border-transparent bg-yellow-500 px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none sm:w-auto sm:text-sm"
                >
                    Fechar
                </button>
            </div>
        </div>
    </modal>
</template>

<script>
import {
ArrowDownCircleIcon,
CheckBadgeIcon,
CheckIcon,
DocumentIcon,
ExclamationCircleIcon,
ExclamationTriangleIcon,
PaperClipIcon,
PencilSquareIcon,
PhotoIcon
} from "@heroicons/vue/24/outline";
import dayjs from "dayjs";
import { useToast } from "vue-toastification";
import HelperMixin from "../../mixins/HelperMixin.vue";
import EmptyState from "../layouts/EmptyState.vue";
import Modal from "../layouts/Modal.vue";
import UpdateDocuments from "./UpdateDocuments.vue";

export default {
    components: {
        UpdateDocuments,
        PaperClipIcon,
        EmptyState,
        ArrowDownCircleIcon,
        CheckBadgeIcon,
        CheckIcon,
        PencilSquareIcon,
        DocumentIcon,
        ExclamationCircleIcon,
        ExclamationTriangleIcon,
        Modal,
        PhotoIcon
    },
    props: {
        user: { type: Object, required: true },
        statuses: { String, required: true },
    },
    mixins: [HelperMixin],
    setup() {
        const toast = useToast();
        return { toast };
    },
    data() {
        return {
            loading: false,
            documentsBankAccount: [],
            documents: [],
            show: false,
            decisionModal: false,
            decision: null,
            image_url: null,
            document: null,
            decisionDocumment: null,
            showDocumentModal: false,
            urlDocument: null,
        };
    },

    mounted() {
        this.fetch(false);
    },

    methods: {
        openModal(document) {
            this.show = true;
            this.document = document;
        },
        formatDate(date) {
            return dayjs(date).format("DD/MM/YYYY HH:mm:ss");
        },
        fetchStatus() {
            axios
                .get("/api/user/" + this.user.id + "/status")
                .then(({ data }) => {
                    this.$emit("update-statuses", data);
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.toast.error(error.response.data.message);
                });
        },
        updateStatus(document, decision) {
            this.decisionModal = true;
            this.decision = decision;
            this.decisionDocumment = document;
        },
        updateStatusFetch(document, decision) {
            const route =
                "/api/user/" +
                this.user.id +
                "/documents/" +
                document +
                "/status";
            axios
                .put(route, { decision: decision })
                .then(({ data }) => {
                    this.toast.success("Salvo com sucesso.");
                    this.fetch(false);
                    this.fetchStatus();
                    this.decisionModal = false;
                    this.decision = null;
                    this.decisionDocumment = null;
                })
                .catch((error) => {});
        },
        fetch(update = true) {
            this.documents = [];
            this.documentsBankAccount = [];
            this.loading = true;

            if (update) {
                this.documents = [];
            }

            axios
                .get("/api/user/" + this.user.id + "/documents")
                .then(({ data }) => {
                    this.documents = [...data, ...this.documents];
                })
                .catch((error) => {});

            if (this.user.bank_accounts.at(-1)?.id !== undefined) {
                axios
                    .get(
                        "/api/bank-account/" +
                            this.user.bank_accounts.at(-1)?.id +
                            "/documents",
                    )
                    .then(({ data }) => {
                        this.documentsBankAccount = [
                            ...data,
                            ...this.documentsBankAccount,
                        ];
                    })
                    .catch((error) => {});
            }

            this.loading = false;
        },
        openLink(url) {
            this.showDocumentModal = true;
            this.urlDocument = url;
            //window.open(url, "_blank");
        },

        sendDocument() {
            if (!this.$refs.file.files[0]) return;

            this.loading = true;

            Vapor.store(this.$refs.file.files[0], {
                progress: (progress) => {
                    this.progress = Math.round(progress * 100);
                },
            }).then((response) => {
                axios
                    .put("/api/user/" + this.user.id + "/documents", {
                        uuid: response.uuid,
                        key: response.key,
                        bucket: response.bucket,
                        name: this.$refs.file.files[0].name,
                        content_type: this.$refs.file.files[0].type,
                        collection: this.document.collection_name,
                    })
                    .then((response) => {
                        this.toast.success("Documento atualizado com sucesso!");
                    })
                    .catch((error) => {
                        this.toast.error("Erro ao atualizar documento!");
                    })
                    .finally(() => {
                        this.loading = false;
                        this.update = false;
                        this.fetch(false);
                        this.show = false;
                    });
            });
        },
    },
};
</script>
